import {scrollToPosition} from "@spoferan/spoferan-ts-core";
import {useIndexStore} from "../store";

export function useSearchPage() {
    const indexStore = useIndexStore();
    const {$device} = useNuxtApp();

    function handleApplyingFilter() {
        nextTick().then(() => {
            scrollToPosition(0); // Easier to scroll to very top to support good UX for desktop and mobile
        });
    }

    function handleSearchInputFocus() {
        if ($device.isMobile) {
            indexStore.isHeaderVisible = false;
        }
    }

    function handleSearchInputBlur() {
        indexStore.isHeaderVisible = true;
    }

    return {
        handleApplyingFilter,
        handleSearchInputFocus,
        handleSearchInputBlur
    }
}